import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import i18n from '../../../locale';
import RightImageSection from '../../../components/RightImageSection';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        pic: imageSharp(fluid: {originalName: {eq: "home_04_solutions.png"}}) {
          fluid(maxHeight: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ pic }) => (
      <RightImageSection
        image={pic}
        title={i18n('Providing the backbone to your unique project at any stage of development')}
        subtitle={[
          i18n('The revolution of connected vehicles and data-driven services has begun and will change the shape of the ecosystem as we know it. At Traffilog, we strive to understand the unique needs of every customer and be the enabler that they are looking for today and in the future.'),
          i18n('Whether you are searching for innovative ways to optimize your fleet, drive customer retention and loyalty, or make vehicles more attractive, you get the tools to work for you. Traffilog has a long history of delivering data-driven solutions for fleet and mobility operators across a variety of segments.')
        ]}
        cta={{
          label: i18n('Solutions'),
          link: i18n('_url:solutions'),
        }}
      />
    )}/>
);