import React from 'react';
import clsx from 'clsx';
import { Link } from 'gatsby';
import { Grid } from '@material-ui/core';
import Img from "gatsby-image/withIEPolyfill";
import Typography from '../Typography';
import SectionWrapper from '../SectionWrapper';
import Button from '../Button';
import styles from './styles.module.sass';

export default ({ image, title, subtitle, cta, className, id }) => (
      <SectionWrapper id={id || null}>
        <Grid container justifyContent='center' alignContent='center' alignItems='center'
          className={className ? clsx(styles.container, className) : styles.container}>
          <Grid item xs={12} sm={12} md={12} lg={6}
            className={clsx(styles.imageWrapper, styles.imageWrapperMobile)}>
            <Img imgStyle={{ objectFit: 'contain' }}
              objectFit='contain'
              className={styles.imageContainer}
              fluid={image.fluid}/>
          </Grid>
          <Grid item container xs={12} sm={12} md={12} lg={6} xl={6}
            alignContent='center'
            alignItems='center'
            justifyContent='flex-start'
            className={styles.callToActionContainer}>
            {title
              ? <Typography variant='h3'
                weight='bold'
                align='left'
                color='dark'
                className={styles.titleContainer}>
                {title}
              </Typography>
              : null
            }
            
            {subtitle
              ? subtitle.map((s, i) => <Typography
                key={`subtitle-${i}`}
                variant='subtitle2'
                weight='light'
                align='left'
                className={styles.subtitleContainer}>
                {s}
              </Typography>)
              : null
            }
            
            {cta
              ? <div className={clsx(
                  styles.buttonContainer
                )}>
                <Link to={cta.link} style={{ textDecoration: 'none'}}>
                  <Button className={styles.button}>
                    {cta.label}
                  </Button>
                </Link>
              </div>
              : null
            }
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}
            className={styles.imageWrapper}>
            <Img imgStyle={{ objectFit: 'contain' }}
              objectFit='contain'
              className={clsx(styles.imageWrapper, styles.imageWrapperWide)}
              fluid={image.fluid}/>
          </Grid>
        </Grid>
      </SectionWrapper>
);