import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Grid } from '@material-ui/core';
import i18n from '../../../locale';
import SectionWrapper from '../../../components/SectionWrapper';
import Typography from '../../../components/Typography';
import Tile from './Tile';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        tile1: imageSharp(fluid: {originalName: {eq: "home_customers_01.png"}}) {
          fluid(maxWidth: 400, maxHeight: 220, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        tile2: imageSharp(fluid: {originalName: {eq: "home_customers_02.png"}}) {
          fluid(maxWidth: 400, maxHeight: 220,  quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        tile3: imageSharp(fluid: {originalName: {eq: "home_customers_03.png"}}) {
          fluid(maxWidth: 400, maxHeight: 220,  quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        tile4: imageSharp(fluid: {originalName: {eq: "home_customers_04.png"}}) {
          fluid(maxWidth: 400, maxHeight: 220,  quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        bgnd: imageSharp(fluid: {originalName: {eq: "home_05_title_bgd.png"}}) {
          fluid(maxWidth: 1024, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ tile1, tile2, tile3, tile4, bgnd }) => (
      <SectionWrapper className={styles.container}>
        <div className={styles.textContainer} style={{ backgroundImage: `url(${bgnd.fluid.src})`}}>
          <Typography variant='h4'
            weight='bold'
            className={styles.titleContainer}>
            As a solutions partner, we make the complex <span className={styles.highlight}>simple.</span>
          </Typography>
        </div>
        <Grid container className={styles.tileContainer}
          justifyContent='center'
          alignContent='space-around'
          alignItems='center'
          spacing={2}>
          <Tile background={tile1}
            text={i18n('Assist in designing the strategy')}
          />
          <Tile background={tile2}
            text={i18n('Clarify needs and deliver solutions')}
          />
          <Tile background={tile3}
            text={i18n('Onboarding and training')}
          />
          <Tile background={tile4}
            text={i18n('Support the ongoing implementation')}
          />
        </Grid>
      </SectionWrapper>
    )}/>
);