import React from "react";
import Content from '../views/Main';

export default ({location}) => (
  <Content location={location} seo={{
    title: "Traffilog | Preferred Solutions Partner for Connected Vehicles",
    lang: 'en',
    description: "Lead the way in connected mobility with Traffilog's powerful platform. Let us help you design and implement a strategy for success today!",
    meta: [{
        name: 'keywords',
        content: 'connected vehicles, connected vehicle services'
      }]
  }}/>
);