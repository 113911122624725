import React from 'react';
import clsx from 'clsx';
import { graphql, StaticQuery, Link } from 'gatsby';
import { Grid } from '@material-ui/core';
import Img from "gatsby-image/withIEPolyfill";
import i18n from '../../../locale';
import Typography from '../../../components/Typography';
import SectionWrapper from '../../../components/SectionWrapper';
import Button from '../../../components/Button';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        pic: imageSharp(fluid: {originalName: {eq: "home_03_platform.png"}}) {
          fluid(maxHeight: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ pic }) => (
      <SectionWrapper className={styles.outerWrap}>
        <Grid container justifyContent='center' alignContent='center' alignItems='center'
          className={styles.container}>
          <Grid item xs={12} sm={12} md={12} lg={6}
            className={styles.imageWrapper}>
            <Img imgStyle={{ objectFit: 'contain' }}
              objectFit='contain'
              className={styles.imageContainer}
              fluid={pic.fluid}/>
          </Grid>
          <Grid item container xs={12} sm={12} md={12} lg={6} xl={6}
            alignContent='center'
            alignItems='center'
            justifyContent='flex-start'
            className={styles.callToActionContainer}>
            <Typography variant='h3'
              weight='bold'
              align='left'
              color='dark'
              className={styles.titleContainer}>
              {i18n('Powerful platform designed to enable connected vehicle services at scale')}
            </Typography>
            <Typography variant='subtitle2'
              weight='light'
              align='left'
              className={styles.subtitleContainer}>
              {i18n('Run your service the way you need it. From plug & play data sources to cloud services and ready-to-use applications, Traffilog offers a flexible and scalable platform that provides the complete infrastructure to build your solution on:')}
            </Typography>
            <ul className={styles.subtitleList}>
              <li>
                {i18n('Connect vehicles in a universal and seamless way via plug&play telematics solutions or direct hardware-free links to vehicles, across brands, models, and types.')}
              </li>
              <li>
                {i18n('Select the cloud services and applications that will run your solution, all with our support and guidance to achieve ultimate results.')}
              </li>
            </ul>
            <Typography variant='subtitle2'
              weight='light'
              align='left'
              className={styles.subtitleContainer}>
              {i18n('We are continuously adding capabilities to the platform, thus connecting it to the leading technology of today and tomorrow.')}
            </Typography>
            <div className={clsx(
                styles.titleContainer,
                styles.buttonContainer
              )}>
              <Link to={i18n('_url:platform')} style={{ textDecoration: 'none'}}>
                <Button className={styles.button}>
                  {i18n('Platform overview')}
                </Button>
              </Link>
            </div>
          </Grid>
        </Grid>
      </SectionWrapper>
    )}/>
);