import React from "react";
import Layout from "../../components/Layout"
import SEO from "../../components/seo";
import sitemap from '../../data/sitemap';
import Hero from './Hero';
import Solutions from './Solutions';
import Platform from './Platform';
import Services from './Services';
import Customers from './Customers';
import CallToAction from '../CallToAction';
import Clients from './Clients';

export default ({ seo, location }) => (
  <Layout overlay page={sitemap._}
    url='_url:main'>
    <SEO {...seo} location={location}/>
    <Hero />
    <Clients />
    <Services />
    <Platform />
    <Solutions />
    <Customers/>
    <CallToAction/>
  </Layout>
);