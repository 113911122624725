import React from 'react';
import { Grid } from '@material-ui/core';
import Img from 'gatsby-image/withIEPolyfill';
import styles from './styles.module.sass';

export default ({ background, text }) => (
  <Grid item xs={12} sm={6} md={6} lg={3} xl={3} className={styles.container}>
      <Img objectFit='cover'
          className={styles.imageContainer}
          fluid={background.fluid}/>
      <div className={styles.tileText}>{text}</div>
  </Grid>
);