import React from 'react';
import { Grid } from '@material-ui/core';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import i18n from '../../../locale';
import Typography from '../../../components/Typography';
import styles from './styles.module.sass';

const partners = [ 1, 2, 3, 4, 5, 6, 7, 8 ];
const partnerDecriptions=[
  i18n('Shared mobility'),
  i18n('Car subscriptions'),
  i18n('Auto-tech '),
  i18n('Tier 1'),
  i18n('Workshop network'),
  i18n('Roadside assistance'),
  i18n('Automotive manufacturer'),
  i18n('Automotive AI tech'),
];

export default () => (
  <StaticQuery
    query={graphql`
      query {
        partner1: imageSharp(fluid: {originalName: {eq: "home_logos_01_sharenow.jpg"}}) {
          fluid(maxWidth: 270, maxHeight: 120, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        partner2: imageSharp(fluid: {originalName: {eq: "home_logos_02_dribe.jpg"}}) {
          fluid(maxWidth: 270, maxHeight: 120, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        partner3: imageSharp(fluid: {originalName: {eq: "home_logos_03_connected_cars.jpg"}}) {
          fluid(maxWidth: 270, maxHeight: 120, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        partner4: imageSharp(fluid: {originalName: {eq: "home_logos_04_continental.jpg"}}) {
          fluid(maxWidth: 270, maxHeight: 120, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        partner5: imageSharp(fluid: {originalName: {eq: "home_logos_06_au2parts.jpg"}}) {
          fluid(maxWidth: 270, maxHeight: 120, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        partner6: imageSharp(fluid: {originalName: {eq: "home_logos_05_best_drive.jpg"}}) {
          fluid(maxWidth: 270, maxHeight: 120, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        partner7: imageSharp(fluid: {originalName: {eq: "home_logos_07_steyr.jpg"}}) {
          fluid(maxWidth: 270, maxHeight: 120, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        partner8: imageSharp(fluid: {originalName: {eq: "home_logos_08_questar.jpg"}}) {
          fluid(maxWidth: 270, maxHeight: 120, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }      
    `}
    render={data => (
      <div className={styles.container}>
        <Typography variant='h5'
          weight='regular'
          color='dark'
          className={styles.titleContainer}>
          {i18n('Trusted by customers across multiple industries')}
        </Typography>
        <Grid className={styles.icons}
          container justifyContent="space-around">
          {partners.map((key, index) => (
            <Grid item key={key} xs={12} sm={6} md={3} lg={1} xl={1} className={styles.icon}>
              <Img imgStyle={{ objectFit: 'contain'}}
                objectFit='contain'
                className={styles.image}
                fluid={data[`partner${key}`].fluid}
                alt={`partner ${key}`}/>
              <div className={styles.iconDescription}>
                {partnerDecriptions[index]}
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    )
  }/>
);