import React from 'react';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import { graphql, StaticQuery } from 'gatsby';
import Img from "gatsby-image/withIEPolyfill";
import i18n from '../../../locale';
import SectionWrapper from '../../../components/SectionWrapper';
import Typography from '../../../components/Typography';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        header: imageSharp(fluid: {
          originalName: {eq: "home_01_hero.jpg"}
        }) {
          fluid(maxHeight: 915, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={data => {
      return (
      <SectionWrapper id='main'>
        <Grid container alignItems='center'
          className={styles.container}
          style={{ backgroundImage: `url(${data.header.fluid.src})`}}
        >
          <Grid item xs={12} sm={12} md={12} lg={6}
            className={clsx(styles.imageContainer, styles.imageContainerMobile)}
          >
            <Img imgStyle={{ objectFit: 'none'}}
              objectFit='none'
              objectPosition='top right'
              className={styles.image}
              fluid={data.header.fluid}
              alt={i18n('The Preferred Solutions Partner for Fleet and Mobility Operators.')}/>
          </Grid>
          <Grid item xs={12} sm={6} lg={5}
            className={styles.textContainer}>
            <Typography
              variant='h1'
              weight='regular'
              align='left'
              className={clsx(
                styles.pitch,
                styles.heroText
              )}>
              {i18n('The Preferred Solutions Partner for Fleet and Mobility Operators.')}
            </Typography>
          </Grid>
        </Grid>
      </SectionWrapper>
    )}}/>
);