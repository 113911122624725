import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import i18n from '../../../locale';
import RightImageSection from '../../../components/RightImageSection';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        pic: imageSharp(fluid: {originalName: {eq: "home_02_services.png"}}) {
          fluid(maxHeight: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ pic }) => (
      <RightImageSection
        image={pic}
        title={i18n('Delivering solutions that enable the safer, greener, and smarter future of mobility')}
        subtitle={[
          i18n('As a solutions partner, we are bringing our overall expertise and support every step of the way to achieve the results our partners and customers strive towards. From designing a strategy based on first-hand knowledge of trends and industry needs to delivering the required products and services, and helping in the ongoing implementation of any projects utilizing the power of connected vehicles.'),
          i18n('Everyone at Traffilog commits to customers’ goals and aspirations and is excited to push forward into new spaces.')
        ]}
        cta={{
          label: i18n('More about us'),
          link: i18n('_url:about-us'),
        }}
      />
      
    )}/>
);